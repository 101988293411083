// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createTheme } from '@mui/material';

const theme = createTheme({
  body: {
    backgroundColor: '#eee !important',
  },
  palette: {
    primary: {
      main: '#0971f1',
      dark: '#053e85',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    lightBlue: {
      main: '#0466E9',
      contrastText: '#fff',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          /* Body/Body bold */
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '16px',
          lineHeight: '19px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: '0',
          margin: '0 !important',
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          color: '#BBBDC0',
          margin: '0 0 26px',
          textTransform: 'capitalize',
          '& .active': {
            color: '#7f8183',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: '12px 18px',
          margin: '4px 0 !important',
          fontSize: '18px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          padding: '8px 18px',
          fontSize: '18px',
          '&.Mui-focused, &.MuiFormLabel-filled': {
            left: '5px',
            top: '-9px',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          padding: '12px 18px',
          margin: '4px 0 !important',
          fontSize: '18px',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          padding: '12px 4px 4px 0px',
          fontSize: '18px',
          '& .MuiInputLabel-root': {
            color: '#000000DE',
            top: '-20px',
          },
          '& .css-1q60rmi-MuiAutocomplete-endAdornment': {
            top: 'calc(50% - 22px)',
          },
        },
      },
    },
  },
});

export default theme;

import { CircularProgress, CircularProgressProps } from '@mui/material';
import { LoadingContainer } from './style';

const themes = {
  purple: 'secondary',
  green: 'success',
  gray: 'inherit',
} as CircularProgressProps;

export type LoadingProps = {
  theme?: string;
  fullscreen?: boolean;
  size?: number;
};

const Loading = ({ theme, fullscreen, size }: LoadingProps) => {
  return (
    <LoadingContainer data-testid={'loading-container'} fullscreen={fullscreen}>
      <CircularProgress
        data-testid={`circular-progress-${theme ? theme : 'default'}`}
        color={theme && themes[theme as keyof typeof themes]}
        size={size && size}
      />
    </LoadingContainer>
  );
};

export default Loading;

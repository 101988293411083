import Loading from 'components/UiComponents/Loading';
import { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const ExpenseKmRefundRoute = lazy(() => import('../components/ExpenseKmRefund'));
const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="mfe-settings-expense-km-refund/:companyId/:userId"
          element={
            <Suspense fallback={<Loading fullscreen />}>
              <ExpenseKmRefundRoute />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;

import styled from 'styled-components';

type LoadingContainerProps = {
  fullscreen: boolean;
};

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props: LoadingContainerProps) => (props.fullscreen ? '100vw' : '100%')};
  height: ${(props: LoadingContainerProps) => (props.fullscreen ? '100vh' : '100%')};
  background-color: ${(props: LoadingContainerProps) => props.fullscreen && 'rgba(251, 251, 251, 0.5)'};
`;
